import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styles from "./projetos.module.css"

import Layout from "../components/layout"
import Button from "../components/button"

const ProjectList = ({projects}) => (
  <div className={styles.lista}>
    {projects.map((project, index) => (
      <div key={index}>
        <div className={styles.container}>
          <div className={styles.projeto} style={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }}>
            <div className={styles.image}>
              <img src={project.node.frontmatter.cover} alt={project.node.frontmatter.title} />
            </div>
            <div className={styles.description}>
              <h2 className={styles.projetoTitle}>{project.node.frontmatter.title}</h2>
              <Button to={`${project.node.frontmatter.path}`}>Veja mais</Button>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

export default function Projetos ({
  data,
}) {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={`Somos uma empresa plural.${' '}
          Juntamos todas as frentes, arquitetura, design de interiores, móveis,${' '}
          incorporações, feiras e eventos para entregar soluções completas para espaços diversos.`}
        />
        <meta property="og:description" content={`Somos uma empresa plural.${' '}
          Juntamos todas as frentes, arquitetura, design de interiores, móveis,${' '}
          incorporações, feiras e eventos para entregar soluções completas para espaços diversos.`}
        />
        <meta name="image" content='/img/voga1.jpg' />
        <meta property="og:image" content='/img/voga1.jpg' />
        <title>Projetos - Grupo Vinte</title>
        <link rel="canonical" href="https://grupovinte.com.br/projetos/" />
        <html lang="pt-br" />
      </Helmet>
      <main style={{ minHeight: '80vh' }}>
        <h1 style={{ textAlign: 'center', fontSize: '20px', textTransform: 'uppercase', fontWeight: 'normal' }}>Projeto</h1>
        <ProjectList projects={edges} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query(
    $type: String!
  ) {
    allMarkdownRemark(
      limit: 1000,
      filter: {
        frontmatter: { type: { eq: $type } }
      },
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            cover
            path
          }
        }
      }
    }
  }
`
